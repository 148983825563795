.page-heading {
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 16px;
  background-color: $gray;
  background-size: auto 130px;
  background-position: right -10px top -14px;

  h1 {
    margin-top: 0;
    margin-bottom: 18px;
    padding-right: 100px;
    font-size: rem(16px);
  }

}

@include large {

  .page-heading {
    padding: 28px 24px;
    background-size: auto 160%;
    background-position: right -50px top -114px;

    h1 {
      font-size: rem(24px);
    }

  }

}
