.checkbox input:checked+.box::after {
  top: -3px;
  right: 0;
  width: 8px;
  height: 16px;
  border-bottom: 2px solid $accent-color;
  border-right: 2px solid $accent-color;
  transform: rotate(45deg);
}

.checkbox a {
  text-decoration: underline;
  color: $accent-color;
}

.options-inline {
  margin-bottom: 20px;

  .checkbox,
  .radio {
    display: inline-flex;
    margin-right: 20px;
  }

}
