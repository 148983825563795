.widget-page {
  margin: 0;
  padding: 0;

  a {
    color: inherit;
  }

}

.dineout-widget {
  max-width: 1200px;
  margin: 30px auto;

  a {
    color: inherit;
  }

}

.widget-page {

  .termsfeed-com---palette-light .cc-nb-okagree,
  .termsfeed-com---palette-light .cc-nb-reject {
    background-color: $accent-color !important;
  }

}

.dineout-widget,
.widget-landing {
  font-size: $base-font-size;
  font-family: $base-font-family;
  line-height: 1.2;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
  }

  input {
    border-radius: 0;
    -webkit-border-radius: 0;
  }

  td,
  th {
    padding: 0;
    width: 38px;
    height: 38px;
  }

  p {
    margin: 0 0 10px;
    padding: 0;
  }

  .white-checkbox .box {
    background-color: $white;
  }

  .CalendarDay__default {
    background-color: $accent-color-hover;
    border: 4px solid $white;
  }

  .CalendarDay__default:hover {
    border: 4px solid $white;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: $accent-color;
    border: 4px solid $white;
  }

  .CalendarDay__default:hover {
    background-color: $accent-color;
    color: $white;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background-color: $accent-color;
    color: $white;
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover,
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    background-color: $gray;
    color: $dark-gray;
  }

  .CalendarMonth_caption {
    padding-top: 0;
    padding-bottom: 43px;
    color: $primary-color;

    strong {
      font-weight: 500;
    }

  }

  .DayPicker_weekHeader_li {
    margin: 0 0.5px;

    small {
      font-size: rem(16px);
      font-weight: 500;
      color: $primary-color;
    }

  }

  .DayPicker_weekHeader {
    top: 36px;
    margin-left: -10px;
  }

  .CalendarMonthGrid__horizontal {
    left: 0;
  }

  .CalendarDay {
    font-size: rem(16px);
    font-weight: 500;
  }

  .DayPicker__horizontal {
    margin: 0 auto;
  }

  .CalendarMonth_caption {

    >div>div {
      margin: 0 5px;
    }

    select {
      height: 24px;
      border-bottom: 0;
      padding-right: 20px;
    }

  }

  .section {
    margin-bottom: 40px;
  }

  .section-title {
    margin: 0 0 22px;
    font-size: rem(24px);
    font-weight: 400;
  }

  .section-sub-title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: rem(16px);
    font-weight: 400;
  }

  p.error,
  p.success {
    margin: 0;
    font-size: rem(12px);
  }

  .error {
    color: $error-color;
  }


  .success {
    color: $success-color;
  }

  .star-ratings {
    display: inline-block;
    margin-right: 10px;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: $awards-color;
    transition: stroke-dashoffset 1s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: $awards-color;
    font-size: rem(26px);
    dominant-baseline: central;
  }

  .lg-two-cols .col {
    margin-bottom: 40px;
  }

  .slick-prev,
  .slick-next {
    @include reset-btn;

    font-size: 0;
  }


  .gray-block {
    flex-shrink: 0;
    width: 190px;
    margin-top: 30px;
    padding: 16px;
    border-radius: 16px;
    background-color: $gray;

    p {
      margin: 0;
    }

    .right {
      display: block;
      text-align: right;
    }

    &.full-width {
      width: 100%;
      margin: 0;
    }

  }

  @include small {

    .gray-block {
      margin-top: 0;
      margin-left: 30px;
    }

  }

  @include large {

    .lg-two-cols,
    .lg-three-cols {
      display: flex;
      justify-content: space-between;
    }

    .lg-two-cols {
      flex-wrap: wrap;

      .col {
        width: 48.3%;
      }

    }

  }


}
