:root {
  --white: #fff;
  --black: #000;
  --primary-color: #2e2e2e;
  --primary-color-hover: #3d3d3d;
  --accent-color: #FFAE42;
  --accent-color-hover: #ffebcd;
  --awards-color: #2f4858;
  --gray: #f1f3f4;
  --dark-gray: #686867;
  --medium-gray: #D6D6D6;
  --light-gray: #E2E2EA;
  --header-border: #b1b1b1;
  --error-color: #d64e2f;
  --success-color: #7CC52C;
  --checkbox-bg: #f5f0e4;
  --border-color: #e6e6e6;
  --footer-color: #726f68;
  --lighter-text-color: #adadad;
}
