// Breakpoints - Media queries.

@mixin small {

  @media only screen and (min-width: 480px) {
    @content;
  }

}

@mixin medium {

  @media only screen and (min-width: 569px) {
    @content;
  }

}

@mixin large {

  @media only screen and (min-width: 1025px) {
    @content;
  }

}

@mixin larger {

  @media only screen and (min-width: 1180px) {
    @content;
  }

}

@mixin max-width($bp) {

  @media only screen and (max-width: $bp) {
    @content;
  }

}

@mixin min-width($bp) {

  @media only screen and (min-width: $bp) {
    @content;
  }

}

// Clearfix - Use on parent of floated elements to clear float.

@mixin clearfix {

  &::after {
    content: "";
    clear: both;
    display: table;
  }

}

// Calculate rem size

@function rem($size) {
  $remSize: calc($size / 16px);
  @return $remSize * 1rem;
}

// Reset button styles

@mixin reset-btn {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}
