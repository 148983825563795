.page-header {
  position: absolute;
  z-index: 6;
  left: 0;
  right: 0;
  top: 0;
  height: 84px;
  background-color: $white;
  border-bottom: 1px solid $header-border;

  a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

}

.logo {
  float: left;
  height: 38px;
  width: 38px;
  margin-top: 11px;
  overflow: hidden;

  svg {
    color: $primary-color;
    height: 38px;
    width: 162px;
  }

}

.website-links {
  background-color: $gray;
  font-size: rem(12px);

  a {
    display: block;
    margin-left: 5px;
    padding: 5px 10px;

    &.selected,
    &:hover {
      background-color: $medium-gray;
    }

  }

  .inner-wrapper {
    display: flex;
    justify-content: flex-end;
  }

}

.header-right {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 18px;

  .btn {
    min-width: 114px;
    height: 30px;
    padding: 3px 10px;
    font-size: rem(16px);
    font-weight: normal;
    text-transform: none;
  }

}

.auth-block {

  .btn {
    display: inline-block;
  }

}

.mobile-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  overflow: auto;
  font-size: rem(21px);
  font-weight: 600;
  background-color: $white;
  border-top: 1px solid $header-border;
  transition: height 0.6s;

  a,
  button {
    display: block;
    padding: 12px 14px;

    &.active {
      background-color: $gray;
    }

  }

  button {
    width: 100%;
    text-align: left;
  }

  &.open {
    height: calc(100vh - 50px);
  }

}

.main-nav {
  padding-top: 20px;
}

.city-list {
  display: none;
  margin-bottom: 10px;
  font-size: rem(14px);
  font-weight: normal;

  a {
    padding: 2px 10px;
  }

}

.mobile-menu-link {
  @include reset-btn;

  position: relative;
  width: 25px;
  height: 18px;
  margin-left: 2px;
  font-size: 0;

  span,
  span::before,
  span::after {
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $primary-color;
  }

  span {
    top: 50%;
    margin-top: -3px;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-duration: 75ms;

    &::before,
    &::after {
      content: '';
    }

    &::before {
      top: -8px;
      transition: top 75ms ease .12s, opacity 75ms ease;
    }

    &:after {
      bottom: -8px;
      transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
    }

  }

  &.open {

    span {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      transform: rotate(45deg);

      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease .12s;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
        transform: rotate(-90deg);
      }

    }

  }

}

@include small {

  .logo {
    height: 42px;
    width: 42px;

    svg {
      height: 42px;
      width: 180px;
    }

  }

  .auth-block {

    .btn {
      margin-right: 12px;
    }


  }

  .header-right .btn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-menu-link {
    width: 30px;
    height: 30px;
    margin-left: 12px;

    span {

      &::before {
        top: -10px;
      }

      &::after {
        bottom: -10px;
      }

    }

  }

}

@include larger {

  .page-header {
    height: 110px;

    .language-switcher {
      margin-right: -5px;
    }

    &.fixed,
    .has-fixed-header & {
      height: 50px;

      .logo {
        height: 40px;
        width: 40px;
        margin-top: 4px;

        svg {
          height: 40px;
          width: 172px;
        }

      }

      .main-nav {
        position: static;
        padding-top: 0;
        font-size: rem(16px);

        li {
          margin: 0 10px;
        }

        a,
        button {
          padding-top: 14px;
        }

      }

      .secondary-nav {
        display: none;
      }

    }

  }

  .website-links {
    font-size: rem(15px);

    a {
      margin-left: 10px;
      padding: 10px 16px;
    }

  }

  .logo {
    position: absolute;
    height: 48px;
    width: auto;
    margin-top: 32px;

    svg {
      height: 48px;
      width: 208px;
    }

  }

  .header-right {
    min-height: 53px;
    margin-top: 0;
  }

  .auth-block {
    margin-left: 16px;

    .btn {
      margin-right: 25px;
    }

  }

  .mobile-menu-link {
    display: none;
  }

  .mobile-menu {
    position: static;
    display: block;
    height: auto;
    padding: 0;
    font-size: rem(18px);
    border-top: 0;
    background-color: transparent;

    &.open {
      height: auto;
    }

  }

  .main-nav,
  .secondary-nav {

    li {
      float: left;
    }

  }

  .secondary-nav {
    font-size: rem(16px);
    font-weight: 300;

    a {
      padding: 17px 25px;
    }

    a:hover,
    a.active,
    a:focus {
      background-color: $gray;
    }

  }

  .main-nav {
    position: absolute;
    right: 6px;
    bottom: 5px;

    li {
      margin: 0 25px;
    }

    a,
    button {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        width: 0;
        height: 3px;
        background-color: $accent-color;
        transition: width 0.4s;
      }

      &.active {
        background-color: transparent;
      }

      &:hover,
      &.active {

        &::after {
          width: 100%;
        }

      }

    }

  }

}