// App-wide colors

$white: var(--white);
$black: var(--black);
$primary-color: var(--primary-color);
$primary-color-hover: var(--primary-color-hover);
$accent-color: var(--accent-color);
$accent-color-hover: var(--accent-color-hover);
$awards-color: var(--awards-color);
$gray: var(--gray);
$dark-gray: var(--dark-gray);
$medium-gray: var(--medium-gray);
$light-gray: var(--light-gray);
$error-color: var(--error-color);
$success-color: var(--success-color);
$checkbox-bg: var(--gray);
$header-border: var(--header-border);
$border-color: var(--border-color);
$footer-color: var(--footer-color);
$lighter-text-color: var(--lighter-text-color);
$box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);


// Theme colors

$lunch-color: #00DB96;
$tasting-color: #A61C3C;
$event-color: #00C5EE;
$discount-color: #7CC52C;
$giftcard-color: #ffb691;

$theme-colors: (
  "default": #FFAE42,
  "dark": #2e2e2e,
  "accent": $accent-color,
  "lunch": $lunch-color, 
  "tasting": $tasting-color, 
  "event": $event-color,
  "discount": $discount-color,
  "giftcard": $giftcard-color,
  "ratings": $awards-color,
  "white": $white,
);

// Typography related variables

$base-font-size: rem(14px);
$base-font-color: $primary-color;
$base-font-family: 'Montserrat',
sans-serif;

// Navigation variable

$top-nav-font-size: rem(16px);
