.widget-landing {

  .icon {
    fill: $accent-color;
  }

  .info-item {
    display: flex;
    align-items: flex-start;
    width: 49%;
    margin-bottom: 22px;

    h3 {
      margin: 0;
      font-weight: 500;
    }

    p {
      margin: 4px 0 0;
    }

    .icon {
      flex-shrink: 0;
      width: 25px;
      height: auto;
      margin-top: -4px;
    }

    .text a {
      display: block;
    }

  }

  .inner-wrapper {
    @include clearfix;

    margin: 0 auto;
    padding: 0 14px;
    max-width: 1348px;
    width: 100%;
  }

}