.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  border: none;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  z-index: 2;
  left: 34px;
  top: 100%;
  width: 100%;
  margin-top: 5px;
  box-shadow: $box-shadow;
  background-color: $white;
  font-size: rem(16px);
  border-radius: 5px;
}

.react-autosuggest__suggestions-list {
  max-height: 300px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent-color;
    border-radius: 20px;
  }

}

.react-autosuggest__suggestions-list .react-autosuggest__suggestion svg {
  position: relative;
  z-index: 1;
  top: 0;
  flex-shrink: 0;
  width: 20px;
  height: auto;
  margin-right: 10px;
  background-color: transparent;
  color: $primary-color;
  transform: none;
}

.react-autosuggest__suggestion {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid $border-color;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $gray;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
