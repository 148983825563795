.discount-page {
  --accent-color-hover: #bee296;
}

.tasting-page {
  --accent-color-hover: #D28D9D;
}

.event-page {
  --accent-color-hover: #95edff;
}


// .giftcard-page {
//   --accent-color-hover: #bee296;
// }

.page-heading-default {
  background-image: url('../images/default.png');
}

.page-heading-discount {
  background-image: url('../images/discount.png');
}

.page-heading-tasting {
  background-image: url('../images/tasting.png');
}

.page-heading-event {
  background-image: url('../images/event.png');
}

.page-heading-menu {
  background-image: url('../images/menu.png');
}

.page-heading-about-us {
  background-image: url('../images/about.png');
}

.text-gray {
  color: $lighter-text-color;
}

.page-heading-default,
.page-heading-discount,
.page-heading-tasting,
.page-heading-event,
.page-heading-menu,
.page-heading-about-us {
  background-position: top right;
  background-repeat: no-repeat;
}

.btn-white.btn-accent:not(.btn-outline) {
  color: $accent-color;
}

@each $name, $color in $theme-colors {

  .#{$name}-page,
  .time-picker-#{$name} {
    --accent-color: #{$color};
  }

  .text-#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }
  
  .btn-#{$name} {
    background: $color;
    border-color: $color;
    transition: box-shadow 0.2s;

    &.btn-outline {
      background: transparent;
      color: $color;
    }

    
    &:hover,
    &:focus {
      box-shadow: 0 0 6px $color;
    }

  }

  .main-nav .border-link-#{$name}::after {
    background-color: $color;
  }

}
